function init() {
    'use strict';

    //
}

document.addEventListener('DOMContentLoaded', init);

(function ($) {

    $(document).ready(function () {
        $('.hero-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            centerMode: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 5000,
            fade: true,
            cssEase: 'ease-in-out',
            arrows: false,
        });
    });
})(jQuery);
